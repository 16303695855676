<template>
  <base-layout>
    <div class="section-header">
      <div class="person-head"><strong>{{person.first_name}} {{person.middle_name}} {{person.last_name}} </strong> ({{person.city}}, {{person.country}})</div>
    </div>
    <ion-content id="wcpt-person-detail" class="has-header has-subheader has-bottom-banner">

      <div class="person-detail-header">
        <img
            class="image"
            v-if="person.vitae_picture"
            :src="'https://fileserver-188ab.kxcdn.com/file/' + person.vitae_picture.split('/')[4]"
            onerror="this.src = '@/assets/user.png'"
        />
        <img class="image" v-if="!person.vitae_picture" src="@/assets/user.png"/>
        <div class="vitae" @click="collapse()" v-bind:style="collapseStyle()"  v-html="person.vitae_text"></div>

         <div v-if="person.vitae_text" class="person-expand-icon mobile-activated">    <i v-if="this.collapsed" class="ion-chevron-down"></i> <i v-if="!this.collapsed" class="ion-chevron-up"></i> </div>
      </div>
      <div class="person-buttons">
        <FavoriteButton class="person-btn" :fav-id="favId" v-if="person.id"></FavoriteButton>
      </div>

      <hr/>
       <div class="person-sessions-header" v-if="sessions.length > 0"> 
        <h3 class="person-sessions-text">Sitzungen</h3>
      </div>
      <session-list :sessions="sessions" :with-time="true"></session-list>

      <div class="person-sessions-header" v-if="presentations.length > 0"> 
        <h3 class="person-sessions-text">Präsentationen</h3>
      </div>
      <presentation-list presentations="presentations" hide-time="hideTime"></presentation-list>

      <div class="person-detail-wrapper" ng-show="person"></div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";
import moment from "moment";
import SessionList from "@/components/SessionList";
import FavoriteButton from "@/components/favorite/FavoriteButton";

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      person: {},
      sessions: [],
      presentations: [],
       collapsed: true,
      favId:null
    };
  },
  components: {
    IonContent,
    "session-list": SessionList,
    FavoriteButton
  },
  methods: {
    ...mapActions("persons", [
      "getPersonById",
      "getAffiliations",
      "getSessions"
    ]),

     ...mapActions('googleanalytics',['trackWithLabel']),
     collapse(){
        this.collapsed = !this.collapsed;
    },

    collapseStyle(){
      if(this.collapsed){
        return {
        'max-height':'140px',
          'min-height':'140px !important',
            };
      }else{
          return {
          'max-height':'none',
          'min-height':'140px !important',
              };
      }
    },
  },

  async created() {
    console.log('persondetail')
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(route) {
        if (route.name == 'Person Detail') {
          this.moment = moment;
          this.person = await this.getPersonById(route.params.id);
          this.favId = 'persons/' + this.person.id;
          this.sessions = await this.getSessions(this.person.id);
          let affiliations = await this.getAffiliations(this.person.id);
          this.presentations = affiliations.presentations
              ? affiliations.presentations
              : [];
          this.trackWithLabel(this.person.display_name)
        }
      }
    }
  }
});
</script>
